<template>
    <div class="map-wrapper">
        <div ref="map" class="map"></div>
    </div>
</template>

<script>
    let Maps = null
    let icons = {}
    const pin_icons = require.context("@/assets/img/pins/", true, /[A-Za-z0-9-_,\s]+\.png$/i)
    pin_icons.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const icon = matched[1]
            icons[icon] = pin_icons(key)
        }
    })
    const axios = require("axios")
    import { mapActions, mapGetters } from "vuex"
    export default {
        name: "Map",
        data(){
            return{
                map: null
            }
        },
        props: {
            mapFilters:{
                type: Object
            }
        },
        async mounted(){
            this.updateLoader(true)
            let coordinates = [48.8895231, 2.2344076] // Nouveau site de WL Paris
            let Microsoft = await this.getMap()
            Maps = Microsoft.Maps
            await this.updateCenter(new Maps.Location(coordinates[0], coordinates[1]))
            this.map = new Maps.Map(this.$refs.map)
            let icon = require("@/assets/img/pic_plan.png") 
            let pushpinCenter = new Maps.Pushpin(this.center, {
                title: this.$t("mapMyPosition"),
                icon: icon
            })
            
            if(this.formattedPins.length == 0){
                this.generatePins()
            }else{
                this.updatePins(this.formattedPins.map(pin => {
                    let a = new Maps.Pushpin(pin.location, pin.options)
                    a.metadata = pin.metadata
                    return a
                })).then(() => {
                    this.pins.forEach(pin => {
                        Maps.Events.addHandler(pin, 'click', this.pushpinClicked)
                    })
                    this.updateLoader(false)
                })
            }
            this.map.entities.push(this.pins)
            this.map.entities.push(pushpinCenter)
            this.map.setView({
                center: this.center,
                zoom: 11,
                padding: 100
            })
        },
        methods:{
            ...mapActions({
                updateLoader: "UPDATE_LOADER",
                updatePins: "pins/updatePins", 
                updateFormattedPins: "pins/updateFormattedPins", 
                updateCenter: "pins/updateCenter",
                selectePinToBook: "bookingDialog/updateSelectedPin",
            }),
            getMap() {
                let script = document.createElement('script')
                script.src = `https://www.bing.com/api/maps/mapcontrol?key=${this.bingToken}&callback=getMap`
                document.head.append(script)
                return new Promise(resolve => {
                    window.getMap = function() {
                        resolve(window.Microsoft)
                    }
                })
            },
            cleanMap(){
                this.map.entities.clear()
            },
            pushpinClicked(e){
                this.selectedPin = e.target
                this.showInfo = true
                this.$emit("showBottomSheet", this.selectedPin)
            },
            updateMapView(reset = false){
                this.map.setView({
                    center: this.center,
                    zoom: 16,
                    padding: 100
                });
            },
            updatePinsAvailability(){
                let myPositionIcon = require("@/assets/img/pic_plan.png") 
                let pushpinCenter = new Maps.Pushpin(this.center, {
                    title: this.$t("mapMyPosition"),
                    icon: myPositionIcon
                })

                this.updatePins(this.formattedPins.map((pin, id) => {
                    let goodIcons = Object.keys(icons).filter(elmt => {
                        let splitted = elmt.split("_")
                        return !(pin.metadata.isPnc ^ splitted[3] === 'pnc') && (pin.metadata.power == splitted[1])
                    })
                    let icon = goodIcons[Math.floor(Math.random() * goodIcons.length)]
                    let choosenTypeParams = icon.split("_")
                    let a = new Maps.Pushpin(pin.location, pin.options)
                    a.setOptions({
                        icon: icons[icon],
                        title: `Station ${id}`
                    })
                    a.metadata = pin.metadata
                    a.metadata.type = choosenTypeParams[2]
                    return a
                })).then(() => {
                    this.pins.forEach(pin => {
                        Maps.Events.addHandler(pin, 'click', this.pushpinClicked)
                    })
                    this.updateLoader(false)
                })
                this.cleanMap()
                this.map.entities.push(this.pins)
                this.map.entities.push(pushpinCenter)
                this.map.setView({
                    center: this.center,
                    zoom: 11,
                    padding: 100
                })
            },
            async generatePins(){
                let pinsBounding = new Maps.LocationRect(this.center, 0.1, 0.5)
                let randomPins = Maps.TestDataGenerator.getPushpins(20, pinsBounding, {color: 'red'})
                this.updatePins(randomPins)
                let sleep = (timeout)=> {
                    console.log("Sleep for ", timeout)
                    return new Promise(resolve => {
                        setTimeout(() => {
                            resolve()
                        }, timeout);
                    })
                }
                for (let id = 0; id < this.pins.length; id++) {
                    let pin = this.pins[id];
                    let icon = Object.keys(icons)[parseInt(Math.random() * Object.keys(icons).length)]
                    let choosenTypeParams = icon.split("_")
                    pin.setOptions({
                        icon: icons[icon],
                        title: `Station ${id}`
                    })
                    pin.metadata = {
                        id,
                        title: `Station ${id}`,
                        description: `Best price: 0.247€/min<br>Rating: 4.5/5`,
                        rating: parseFloat(Math.random() + 4).toFixed(1),
                        reviews: parseInt(Math.random() * 200),
                        pricePerMinutes: 0.247,
                        power: choosenTypeParams[1],
                        type: choosenTypeParams[2],
                        isPnc: choosenTypeParams[3] === 'pnc',
                        address: "Z.I.<br>Rue de la Pointe<br>59113 SECLIN"
                    }
                    let {data} = await axios.get("https://dev.virtualearth.net/REST/v1/Locations/"+pin.getLocation().latitude +"," + pin.getLocation().longitude+"?&includeNeighborhood=1&o=json&key=An1LBwiA2em_GmqpT57Mei_cH498KOFiVREltMI9d2oBVOgktDTHirSKs89dITQ9")
                    pin.metadata.address = data["resourceSets"][0]["resources"][0]["address"]["formattedAddress"]
                    Maps.Events.addHandler(pin, 'click', this.pushpinClicked)
                    await sleep(100)
                }

                let formattedPins = this.pins.map(pin => {
                    return {
                        location: pin.getLocation(),
                        metadata: pin.metadata,
                        options: {
                            icon: pin.getIcon(),
                            title: pin.getTitle()
                        }
                    }
                })
                this.updateLoader(false)
                this.updateFormattedPins(formattedPins)
            }
        },
        computed:{
            ...mapGetters({
                pins: "pins/pins",
                searchPin: "pins/searchPin",
                center: "pins/center",
                bingToken: "pins/bingToken",
                formattedPins: "pins/formattedPins",
                bookedPins: "bookingDialog/bookedPins",
                selectedBookingPin: "bookingDialog/selectedPin"
            })
        },
        watch:{
            headerHeight(newVal){
                this.headerHeight = newVal
            },
            searchPin(){
                if(this.searchPin != null){
                    this.updateMapView()
                }else{
                    this.updateMapView(true)
                }
            },
            async bookedPins(newVal){
                let a = newVal.filter(pin => pin.pinId == this.selectedBookingPin.metadata.id)
                let b = this.pins.filter(pin => pin.metadata.id == a[0].pinId)
                await this.updateCenter(b[0].getLocation())
                this.updateMapView()
            },
            mapFilters: {
                async handler() {
                    if(this.mapFilters != null){
                        let filteredPins = this.pins.filter(pin => pin.metadata.type == "green" && pin.metadata.pricePerMinutes < this.formatNumber(0.20 + this.mapFilters.priceFilter / 1000, 3))
                        console.log(filteredPins)
                        // await this.updateCenter(filteredPins[Math.floor(Math.random() * filteredPins.length)].getLocation())
                        this.updatePinsAvailability()
                        this.$emit("filtersApplied")
                    }
                },
                deep: true
            }
        }
    }
</script>

<style scoped>
    .map-wrapper{
        height: 100%;
    }
    .map{
        z-index: 0;
    }
</style>