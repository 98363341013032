<template>
    <div class="pa-3 home-wrapper" :style="`height: calc(100 * ${vh}px - ${headerHeight}px)`">
        <div class="d-flex flex-column home-search-point">
            <v-text-field ref="search" :label="$t('homeSlotSearchLabel')" append-icon="mdi-magnify" readonly hide-details clearable dense solo></v-text-field>
            <v-row class="mt-2 align-center justify-center" no-gutters>
                <v-col class="mr-1" cols="5">
                    <v-menu v-model="showDatePicker" ref="menu" content-class="filter-picker mt-2" :close-on-content-click="false" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="computedDateFormatted" :label="$t('homeDateInputLabel')" hide-details dense solo readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" @change="save" @input="showDatePicker = false" :min="currentDate.toISOString()" full-width>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="showDatePicker = false">Annuler</v-btn>
                            <v-btn text color="primary" @click="showBeginPicker = false">Valider</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <!-- <v-text-field label="Date" ></v-text-field> -->
                </v-col>
                <v-col class="mx-1" cols="3">
                    <v-menu v-model="showBeginPicker" ref="menu" :close-on-content-click="false" content-class="filter-picker mt-2" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="selectedBegin" readonly :label="$t('homeBeginTimeInputLabel')" hide-details dense solo v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-model="selectedBegin" @change="save" :min="getCurrentDate('DD/MM/YYYY') != computedDateFormatted ? null : formatDate(currentDate, 'HH:mm')" format="24hr" @input="showBeginPicker = false" full-width>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="showBeginPicker = false">Annuler</v-btn>
                            <v-btn text color="primary" @click="showBeginPicker = false">Valider</v-btn>
                        </v-time-picker>
                    </v-menu>
                </v-col>
                <v-col class="ml-1" cols="3">
                    <v-menu v-model="showEndPicker" ref="menu" :close-on-content-click="false" content-class="filter-picker mt-2" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="selectedEnd" readonly :label="$t('homeEndTimeInputLabel')" hide-details dense solo v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-model="selectedEnd" @change="save" :min="selectedBegin" format="24hr" @input="showEndPicker = false" full-width>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="showEndPicker = false">Annuler</v-btn>
                            <v-btn text color="primary" @click="showBeginPicker = false">Valider</v-btn>
                        </v-time-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <div class="d-flex">
                <div class="d-flex align-center">
                    <span @click="showFilters = !showFilters" class="mt-1 text-decoration-underline font-italic text-caption">{{$t('homeMoreFilters')}}</span>
                    <v-icon class="mt-1" size="18">{{showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <span @click="showLegend = !showLegend;" class="mt-1 text-decoration-underline font-italic text-caption">{{$t('homeMoreLegend')}}</span>
                    <v-icon class="mt-1" size="18">{{showLegend ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                </div>
            </div>
            <div v-if="showLegend" class="my-2 d-flex flex-column legend">
                <div class="d-flex align-center justify-start legend-item">
                    <v-avatar size="24">
                        <v-img src="@/assets/img/pic_plan.png" alt="Legend" contain />
                    </v-avatar>
                    <span class="ml-1 text-subtitle-2">{{$t("legendCurrentPosition")}}</span>
                </div>
                <div class="d-flex align-center justify-start legend-item">
                    <v-avatar size="24">
                        <v-img src="@/assets/img/pin_red.png" alt="Legend" contain />
                    </v-avatar>
                    <span class="ml-1 text-subtitle-2">{{$t("legendUnavailable")}}</span>
                </div>
                <div class="d-flex align-center justify-start legend-item">
                    <v-avatar size="24">
                        <v-img src="@/assets/img/pin_green.png" alt="Legend" contain />
                    </v-avatar>
                    <span class="ml-1 text-subtitle-2">{{$t("legendAvailable")}}</span>
                </div>
                <div class="d-flex align-center justify-start legend-item">
                    <v-avatar size="24">
                        <v-img src="@/assets/img/pin_pnc.png" alt="Legend" contain />
                    </v-avatar>
                    <span class="ml-1 text-subtitle-2">{{$t("legendPnc")}}</span>
                </div>
                <div class="d-flex align-center justify-start legend-item">
                    <v-avatar color="grey" size="24" dark>
                        <span class="white--text lengend-power-text">--kW</span>
                    </v-avatar>
                    <span class="ml-1 text-subtitle-2">{{$t("legendDeliveredPower")}}</span>
                </div>
            </div>
            <div v-if="showFilters" class="my-1 d-flex flex-column">
                <p class="mb-1 font-weight-bold text-subtitle-1">{{$t('homeFilterSpeed')}}</p>
                <div class="charge-power-filter">
                    <v-checkbox class="mt-0" v-model="filters.chargePower" :label="`${$t('homeFilterSpeedNormal')} (3kW - 8kW)`" color="primary" value="normal" small hide-details ></v-checkbox>
                    <v-checkbox class="mt-0" v-model="filters.chargePower" :label="`${$t('homeFilterSpeedAccelerated')} (8kW - 23kW)`" color="primary" value="accelerated" small hide-details></v-checkbox>
                    <v-checkbox class="mt-0" v-model="filters.chargePower" :label="`${$t('homeFilterSpeedFast')} (23kW - 120kW)`" color="primary" value="fast" small hide-details></v-checkbox>
                </div>
                <p class="mb-1 font-weight-bold text-subtitle-1">{{$t('homeFilterType')}}</p>
                <div class="charge-type-filter">
                    <v-checkbox class="mt-0" v-model="filters.chargeType" label="TYPE 2" color="primary" value="type-2" small hide-details ></v-checkbox>
                    <v-checkbox class="mt-0" v-model="filters.chargeType" label="COMBO CCS EU" color="primary" value="combo" small hide-details></v-checkbox>
                    <v-checkbox class="mt-0" v-model="filters.chargeType" label="BEST PRICE" color="primary" value="bestprice" small hide-details></v-checkbox>
                </div>
                <p class="mb-1 font-weight-bold text-subtitle-1">{{$t('homeFilterMaxPrice')}} - {{ formatNumber(0.20 + filters.priceFilter / 1000, 3) }}€/min</p>
                <div class="charge-price-filter">
                    <v-slider v-model="filters.priceFilter" :min="0" :max="300" track-color="#46BEAA" track-fill-color="#2D8C8C" hide-details></v-slider>
                </div>
                <v-btn color="primary" class="align-self-end" @click="handleApplyFilters()" small>{{$t('homeFilterApplyBtn')}}</v-btn>
            </div>
            <div v-if="bookedPins && bookedPins.length > 0" class="ma-0 d-flex align-baseline primary--text text-center text-subtitle-1 font-weight-bold">
                <v-avatar color="primary" class="mr-1" size="18" dark>
                    <v-icon small dark>mdi-check</v-icon>
                </v-avatar>
                <span class="subtitle-2">{{ $t("homeSlotGeneralBookedWithDate", {station: pinById(bookedPins[0].pinId).metadata.title, date: formatDate(bookedPins[0].bookedDate), time: bookedPins[0].bookedTime})}}</span>
            </div>
            <v-btn v-if="bookedPins && bookedPins.length > 0" class="align-self-center mt-2" color="primary" dark @click="$router.push({ name: 'Itinerary' })">
                <v-icon dark>mdi-directions</v-icon>
                <span class="text-caption">{{$t("infoItineraryBtn")}}</span>
            </v-btn>
        </div>
        <div class="home-body">

            <v-btn
                elevation="2"
                fab
                x-large
                color="primary"
                @click="sendMQTTContact"
                style="position: absolute;
                    bottom: 8px;
                    right: 8px;
                    z-index: 1;"
            >
                <v-icon dark>mdi-face-agent</v-icon>
            </v-btn>

            <Map :mapFilters="mapFilters" @filtersApplied="mapFilters = null" @showBottomSheet="handlePinClicked($event)"/>
        </div>
        <div v-if="false" class="d-flex justify-center home-footer">
            <v-btn class="mx-auto my-2" color="primary" @click="handleScheduleBooking()">{{$t('homeScheduleBtn')}}</v-btn>
        </div>
        <v-bottom-sheet v-model="showInfo" v-if="selectedPin != null">
            <v-sheet class="d-flex flex-column bottom-sheet-border" elevation="4">
                <v-btn class="mt-2 mr-2 align-self-end" @click="showInfo = !showInfo" icon><v-icon>mdi-close</v-icon></v-btn>
                <!-- <div class="px-3 mb-3 d-flex flex-column justify-start align-center" v-if="selectedPin != null">

                </div> -->
                <v-card flat>
                    <v-card-title>{{selectedPin.metadata.title}}</v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mx-0">
                            <v-rating :value="parseFloat(selectedPin.metadata.rating)" color="amber" dense half-increments readonly size="14"></v-rating>
                            <div class="grey--text ms-4"> {{selectedPin.metadata.rating}} ({{selectedPin.metadata.reviews}}) </div>
                        </v-row>
                        <div class="my-4 text-caption">{{selectedPin.metadata.pricePerMinutes}}/min • {{ formattedAddress }}</div>
                        <div class="text-center font-weight-bold" v-if="selectedPinBooking">{{ $t("homeSlotBookedWithDate", {date: formatDate(selectedPinBooking.bookedDate), time: selectedPinBooking.bookedTime}) }} <span v-if="selectedPinBooking.chargeDuration && selectedPinBooking.chargeDuration.value">{{ $t("homeSlotBookedWithDuration", { duration: selectedPinBooking.chargeDuration.text }) }}.</span></div>
                        <div class="summary-wrapper">
                            <span class="text-subtitle-2">Available sockets:</span>
                            <div class="mt-4 d-flex align-stretch justify-center summary-items">
                                <div class="mr-4 d-flex flex-column align-center justify-center summary-item">
                                    <v-avatar size="24">
                                        <v-img src="@/assets/img/type2_cable_attached.jpg" alt="Legend" contain />
                                    </v-avatar>
                                    <span class="text-overline">TYPE2</span>
                                </div>
                                <div class="ml-4 d-flex flex-column align-center justify-center summary-item">
                                    <v-avatar size="24">
                                        <v-img src="@/assets/img/combo_ccs_eu.jpg" alt="Legend" contain />
                                    </v-avatar>
                                    <span class="text-overline">COMBO CCS</span>
                                </div>
                                <div class="ml-4 d-flex flex-column align-center justify-center summary-item">
                                    <v-avatar size="24">
                                        <v-img src="@/assets/img/combo_ccs_eu.jpg" alt="Legend" contain />
                                    </v-avatar>
                                    <div class="d-flex flex-column">
                                        <p class="ma-0"><span class="text-overline">BEST PRICE</span><small>({{selectedPin.metadata.pricePerMinutes}}/min)</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
                <v-bottom-navigation class="elevation-0" :value="value" color="primary" grow >
                    <v-btn @click="$router.push({ name: 'Itinerary' })">
                        <span class="text-caption">{{$t("homeItineraryBtnLabel")}}</span>
                        <v-icon>mdi-directions</v-icon>
                    </v-btn>

                    <v-divider class="mx-3" vertical></v-divider>

                    <v-btn @click="updateShowDialog(true)">
                        <span class="text-caption">{{$t("homeBookSlotBtnLabel")}}</span>
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>

                    <v-divider class="mx-3" vertical></v-divider>

                    <v-btn @click="handleMoreInfo()">
                        <span class="text-caption">{{$t("homeMoreInfoBtnLabel")}}</span>
                        <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                </v-bottom-navigation>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
    let icons = []
    const pin_icons = require.context("@/assets/img/pins/", true, /[A-Za-z0-9-_,\s]+\.png$/i)
    pin_icons.keys().forEach((key, id) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const icon = matched[1]
            const splittedIcon = icon.split("_")
            icons.push( {
                id,
                available: splittedIcon[2] === "green",
                power: splittedIcon[1],
                isPnc: splittedIcon[3] === "pnc",
                src: pin_icons(key)
            })
        }
    })
    import { mapActions, mapGetters } from "vuex"
    import Map from "@/components/Map"
    export default {
        name: 'Home',
        components: { Map },
        data(vm){
            return {
                map: null,
                page: {
                    title: "homePageTitle",
                    description: null
                },
                selectedPin: null,
                tmpSearchPin: null,
                showInfo: false,
                value: 0,
                step: 1,
                showDatePicker: false,
                showBeginPicker: false,
                showEndPicker: false,
                currentDate: new Date(),
                showFilters: false,
                showLegend: false,
                filters:{
                    chargePower: ["normal", "fast", "accelerated"],
                    chargeType: ["type-2", "combo", "bestprice"],
                    priceFilter: 300
                },
                mapFilters: null,
                legendIcons: icons
            }
        },
        mounted(){
            console.log("CMK")
            let autocompleteInput = this.$refs.search.$refs.input
            autocompleteInput.addEventListener('focus', this.onFocus, true)
            this.selectedEnd = this.addHoursToTime(this.selectedBegin, 1)
            let fromNavigation = sessionStorage.getItem("IEC_GO_TO_INFO") == "true"
            if(fromNavigation){
                this.selectedPin = this.$store.getters["bookingDialog/selectedPin"]
                this.showInfo = this.selectedPin != null
                sessionStorage.removeItem("IEC_GO_TO_INFO")
            }

        },
        methods:{
            ...mapActions({
                updateShowDialog:'bookingDialog/updateShowDialog',
                selectPinToBook: "bookingDialog/updateSelectedPin"
            }),
            handlePinClicked(e){
                console.log(e)
                this.selectPinToBook(e)
                this.selectedPin = e
                this.showInfo = true
            },
            handleMoreInfo(){
                this.$router.push({name: 'PinInfo', params: {
                    pinId: this.selectedPin.metadata.id
                }})
            },
            onFocus(){
                this.$refs.search.isMenuActive = true
            },
            handleScheduleBooking(){
                this.$refs.search.focus()
            },
            handleChange(){
                this.$refs.search.blur()
                setTimeout(() => {
                    this.searchPin = this.tmpSearchPin

                }, 500);
            },
            save(e){
                console.log(e)
                this.activePicker = false;
            },
            handleApplyFilters(){
                this.showFilters = false
                this.mapFilters = this.filters
            },
            sendMQTTContact(){
                let topic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.OCULAR_ACTION
                let message = {
                    action: "goto",
                    sequenceId: "4"
                }
                this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                    console.log("Sequence 4 started")
                } )
            }
        },
        computed:{
            ...mapGetters({
                pins: "pins/pins",
                center: "pins/center",
                formattedPins: "pins/formattedPins",
                selectedPinBooking: "bookingDialog/selectedPinBooking",
                bookedPins: "bookingDialog/bookedPins",
                pinById: "pins/getPinById",
            }),
            searchPin: {
                get(){
                    return this.$store.getters["pins/searchPin"]
                },
                set(value){
                    this.$store.dispatch('pins/updateSearchPin', value)
                }
            },
            selectedBegin: {
                get(){
                    return this.$store.getters["bookingDialog/selectedTime"]
                },
                set(value){
                    this.$store.dispatch('bookingDialog/updateSelectedTime', value)
                }
            },
            selectedEnd: {
                get(){
                    return this.$store.getters["bookingDialog/selectedEndTime"]
                },
                set(value){
                    this.$store.dispatch('bookingDialog/updateSelectedEndTime', value)
                }
            },
            date: {
                get(){
                    return this.$store.getters["bookingDialog/selectedDate"]
                },
                set(value){
                    this.$store.dispatch('bookingDialog/updateSelectedDate', value)
                }
            },
            formattedSelectedPin(){
                if(this.selectedPin){
                    let a = this.formattedPins.filter(pin => pin.metadata.id == this.selectedPin.metadata.id )
                    if(a.length > 0){
                        return a[0]
                    }
                }
                return null
            },
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            formattedAddress(){
                if(this.selectedPin){
                    return this.selectedPin.metadata.address.split("<br>").join(" ")
                }
                return null
            }
        },
        watch:{
            headerHeight(newVal){
                this.headerHeight = newVal
            },
            selectedBegin(newVal){
                this.selectedEnd = this.addHoursToTime(newVal, 1)
                this.mapFilters = {date: this.selectedEnd}
            }
        }
    }
</script>
<style scoped>
    .home-title{
        font-weight: bold;
        font-size: 1.2rem;
    }
    .home-wrapper{
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        row-gap: 16px;
    }
    .bottom-sheet-border{
        border-radius: 12px;
    }
    .filter-picker{
        left: 0 !important;
        right: 0 !important;
        width: 100%;
        max-width: 100%;
    }
    .legend{
        display: grid !important;
        grid-template-columns: repeat(2,auto);
        grid-gap: 8px;
    }
    .lengend-power-text{
        font-size: 0.55rem;
    }
    .home-body{
        position: relative;
    }
</style>
